export default function Hamburger() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g id="heroicons-solid:menu">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 6.05078C1.5 5.71926 1.65804 5.40132 1.93934 5.1669C2.22064 4.93248 2.60218 4.80078 3 4.80078H21C21.3978 4.80078 21.7794 4.93248 22.0607 5.1669C22.342 5.40132 22.5 5.71926 22.5 6.05078C22.5 6.3823 22.342 6.70024 22.0607 6.93466C21.7794 7.16909 21.3978 7.30078 21 7.30078H3C2.60218 7.30078 2.22064 7.16909 1.93934 6.93466C1.65804 6.70024 1.5 6.3823 1.5 6.05078ZM5.5 12.3008C5.5 11.9693 5.65804 11.6513 5.93934 11.4169C6.22064 11.1825 6.60218 11.0508 7 11.0508H21C21.3978 11.0508 21.7794 11.1825 22.0607 11.4169C22.342 11.6513 22.5 11.9693 22.5 12.3008C22.5 12.6323 22.342 12.9502 22.0607 13.1847C21.7794 13.4191 21.3978 13.5508 21 13.5508H7C6.60218 13.5508 6.22064 13.4191 5.93934 13.1847C5.65804 12.9502 5.5 12.6323 5.5 12.3008ZM1.5 18.5508C1.5 18.2193 1.65804 17.9013 1.93934 17.6669C2.22064 17.4325 2.60218 17.3008 3 17.3008H21C21.3978 17.3008 21.7794 17.4325 22.0607 17.6669C22.342 17.9013 22.5 18.2193 22.5 18.5508C22.5 18.8823 22.342 19.2002 22.0607 19.4347C21.7794 19.6691 21.3978 19.8008 21 19.8008H3C2.60218 19.8008 2.22064 19.6691 1.93934 19.4347C1.65804 19.2002 1.5 18.8823 1.5 18.5508Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
