export default function DownIt() {
  return (
    <svg width="13" height="9" viewBox="0 0 13 9" fill="none">
      <path
        d="M12 1L6.62222 7L0.999999 1"
        stroke="#1a325d"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
