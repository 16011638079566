import styles from "./header.module.scss";
import cn from "clsx";
import Search1Logo from "@svgIcons/Search1";
import DownLtLogoBlue from "@svgIcons/DownIt";
import dynamic from "next/dynamic";
import { Suspense, useContext } from "react";
import { ModalTypes } from "@/types/modal";
import { GA_EVENTS, GAEvent } from "@/helpers/gaEvents";
import { pushToDataLayer } from "@/helpers/gtag";

import { ModalContext } from "@/context/ModalContext"
import { IModalActions } from "@/types/modal";
import { getGlobalStateValue, updateStateSession } from "@/context/utils";
import ClientSideButton from "@/components/molecules/Carousels/HeroCarousel/ClientSideButton";
import RightArrow from "@/SvgIcons/RightArrow";

const ProgramMenu = dynamic(() => import("@molecules/ProgramMenu/ProgramsMenu"));

export default function MobileMenu({
  menuOpen, 
	headerMenu, 
	programMenuOpen,
	setProgramMenuOpen,
	programMenuList,
	programDomains,
	SearchBox,
	results,
  associatedPrograms,
  getProgramsData,
  isSponsoredCertificates
}){
  const { LEAD_INITIATE, NAVIGATION_CLICK } = GAEvent;
  const { modalDispatch } = useContext(ModalContext);

  const getProgramMenus = async (e) => { 
    try {
        await getProgramsData();
        setProgramMenuOpen(true);

        window.webengage.track("Programs Clicked");

    } catch (error) {
      setProgramMenuOpen(false);
      console.error(error);
    }
  }
  
  const handleGaClickEnroll = () => {
   
    CTAHandaler();

    setTimeout(()=>{

      pushToDataLayer({
        ...GA_EVENTS.get(LEAD_INITIATE),
        cta_text: headerMenu.CTA.text || "NA",
        section_name: "Header",
        header_title: "NA",
        page_type: getGlobalStateValue('pageType'),
        page_url : window?.location?.href || "NA",
      })
      updateStateSession({
        parentSection : "Header"
      });

    },2500)
   
    return;
  }

  const handleGaClick = (payload={}, heading) => {
    pushToDataLayer({...payload});
    
    if(heading == "CAREER SERVICES"){
      window.webengage.track("Career Advice Clicked")
    } else if(heading == "ADVANTAGES"){
      window.webengage.track("Advantages Clicked")
    }
  }

  const CTAHandaler = ()=>{
    modalDispatch({
          type: IModalActions.OpenModal,
          data: {
             modalType: ModalTypes.ConnectModal,
             modalData: { associatedPrograms, isLandingPage : true, },
          },
        })
  }

  const studentLoginHandler = () => {
    modalDispatch({
      type: IModalActions.OpenModal,
      data: {
         modalType: ModalTypes.LoginModal,
      },
    })

    setTimeout(() => {
      pushToDataLayer({
        "event": "student_login_initiate",
        "cta_text": "STUDENT LOGIN",
        "page_type": getGlobalStateValue("pageType"),
        "page_url": location.href
      });
    }, 2500);

  }

  const searchBoxHandler = () => {
    if (!Object.keys(programMenuList).length) {
      getProgramsData();
    }
  };

	return (
    <div
      className={cn(styles.mobileMenu, "flex flex-col items-start", {
        ["hidden"]: !menuOpen,
      })}
    >
      <div className={styles.btnContainerTop} >
        <div className={cn("cursor-pointer relative", styles.searchBox)} onClick={searchBoxHandler}>
          <div className={cn("absolute", styles.searchIcon)}>
          <Search1Logo />
          </div>
          
          {SearchBox}
        </div>

        {!results?.length &&
          !programMenuOpen &&
          headerMenu?.MenuItem?.filter((x) => x.enabled)?.map(
            ({ Item, SubItem }, index) => (
              <div
                className={cn("flex items-center", styles.menuItem)}
                key={index}
              >
                <a
                  href={Item.url}
                  target={Item.target || "_self"}
                  key={Item.id}
                  className="flex justify-center items-center"
                  onClick={(e) => {
                    if (index == 0) {
                      e.preventDefault();
                      getProgramMenus(e);
                    }else{
                      handleGaClick(
                        {
                          event : NAVIGATION_CLICK,
                          cta_text: Item?.text || "", 
                          category : Item?.title || "",
                          sub_category : "NA",
                          section_name : "hamburger",
                          redirection_url : Item?.url || "",
                          page_type : getGlobalStateValue('pageType')  || "Home Page",
                          header_title : "NA"
                        },
                        Item.text
                      )
                    }
                  }}
                >
                  <span className="uppercase mr-3">{Item.text}</span>
                  {index == 0 && (
                    <DownLtLogoBlue />
                  )}
                </a>
              </div>
            ),
          )}
      </div>

      <div className={cn(styles.programMenu)}>
        <Suspense fallback={null}>
          <ProgramMenu
            isMobile
            goBack={() => {
              setProgramMenuOpen(false)
            }}
            programMenu={programMenuList}
            programDomains={programDomains}
            programMenuOpen={programMenuOpen}
          />
        </Suspense>
      </div>
      
      {!results?.length && !programMenuOpen && (
        <div
          className={cn(
            styles.btnContainer,
            "mt-auto flex flex-col items-start",
          )}
        >
          {headerMenu?.LoginCTA?.enabled && (
              <button
                onClick={studentLoginHandler}
                className={styles.studentLogin}
              >
                Existing Student Login <RightArrow stroke="#1a325d"/>
              </button>
          )}

          {headerMenu?.CTA?.enabled && (
            <ClientSideButton
              GADataNew={{
                ...GA_EVENTS.get(LEAD_INITIATE),
                cta_text: "Apply Now",
                section_name: "Header",
                header_title: "NA",
                isPageType: true,
                page_url : true
              }}
              
              webEngageEvents= {{
                "Section Name": "Header",
                isPageType: true
              }}
              modalType={isSponsoredCertificates ? ModalTypes.ConnectModalFormSponsoredCourses : ModalTypes.ConnectModal}
              modalData={{ associatedPrograms, isLandingPage: true }}
              ctaText ={ isSponsoredCertificates ? "ENROLL NOW" : "APPLY NOW" }
            />
          )}
        </div>
      )}
    </div>
  );
}

