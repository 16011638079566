"use client";

import Image from "next/image";
import HamburgerLogo from "@svgIcons/Hamburger";
import CloseLogo from "@svgIcons/Close";
import { SvgToUse } from "@/components/atoms/Buttons/WarningButton";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./header.module.scss";
import cn from "clsx";
import { searchPrograms } from "@molecules/ProgramMenu/constants";
import { pushToDataLayer } from "@/helpers/gtag";
import { GAEvent, GA_EVENTS } from "@/helpers/gaEvents";
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'
import Script from "next/script";
import { usePathname } from "next/navigation";
import { getGlobalStateValue } from "@/context/utils";
import VideoCall from "./VideoCall/VideoCall";
import throttle from "lodash/throttle";
import { useDebounce } from "@/helpers/debouncedFunctionReact";
const toggleScroll = (flag: boolean) => {
  const htmlEl = document.querySelector("html");
  if (flag) {
    htmlEl.style.overflowY = "hidden";
  } else {
    htmlEl.style.overflowY = "auto";
  }
};

const { NAVIGATION_CLICK } = GAEvent;

const handleGaClick = (payload = {}) =>{
  const gaData = GA_EVENTS.get(NAVIGATION_CLICK);
  pushToDataLayer({...gaData,...payload});
}

export const LinkButton = ({ button, CN }: any) => {
 
  return (
    <div className={cn(styles.linkBtn, CN)}>
      <a
        href={button?.url || "/"}
        target={button?.target}
        onClick={(e) => { 
          handleGaClick({
            cta_text: button?.text || "", 
            category : "Programs",
            sub_category : "Domain wise",
            section_name : "header",
            redirection_url : button?.url || "",
            page_type : getGlobalStateValue('pageType') ,
            header_title : "NA"
          })
          toggleScroll(false)
        }}
        className="flex items-center justify-center"
      >
        <span className="uppercase font-raleway mr-3">{button?.text}</span>
        <SvgToUse type={"normal"} iconDirection={"right"} />
      </a>
    </div>
  );
};

export default function Header({
  headerMenu,
  associatedPrograms,
  isSponsoredCertificates = false
}: {
  headerMenu: { MenuItem: headerMenu[]; Logo: any; CTA: any; LoginCTA: any };
  associatedPrograms:  Array<ProgramDetails>;
  isSponsoredCertificates?: boolean
}) {
  const { CALL_US_NAVBAR, CALL_CHAT_INTERACTION } = GAEvent;
  const [menuOpen, setMenuOpen] = useState(false);
  const [results, setResults]: any = useState([]);
  const [showSearchBox, toggleSearch] = useState(false);
  const [programMenuOpen, setProgramMenuOpen] = useState(false);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [isDelayingEngatiScript, setIsDelayingEngatiScript] = useState(false)
  const [programDomains, setProgramDomains]:any = useState({});
  const [programMenuList, setProgramMenuList] : any = useState({});
  const [cache, setCache] = useState<{ domains: any; menuList: any } | null>(
    null
  );
  const cacheTimeout = useRef<NodeJS.Timeout | null>(null);
  const memoizedDomains = useMemo(() => programDomains, [programDomains]);
  const memoizedMenuList = useMemo(() => programMenuList, [programMenuList]);
  const headerRef = useRef<HTMLInputElement>(null);
  const programMenuRef = useRef<HTMLInputElement>(null);
  const ref = useRef<HTMLInputElement>(null);
  const observerRef = useRef(false);
  

  const pathName = usePathname();

  useEffect(()=>{
    pushToDataLayer({
      event : "page_type",
      page_type : getGlobalStateValue('pageType')
    })
  },[pathName]);

  useEffect(() => {
    function handleDocumentClick(event) {
      const searchContainer = document.getElementById("searchContainer");
      if (searchContainer && !searchContainer.contains(event.target)) {
        toggleSearch(false);
        setResults([]);
      }
    }
  
    document.addEventListener("click", handleDocumentClick);
  
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 15 && !isSponsoredCertificates) {  
        setIsDelayingEngatiScript(true)
        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setTimeout(()=>{
      const targetElement = document.getElementById("secondary-menu");
    
      if(targetElement){
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              observerRef.current = true 
            } else {
              observerRef.current = false
            }
          });
        });
    
        observer.observe(targetElement);
      }

    },3000)

   


    const handleScroll = () => {
      if (window.scrollY > 100) {
        if (!programMenuOpen) {
          if (window.scrollY < lastScrollPosition && !observerRef.current) {
            //here
            setIsHeaderFixed(true);
          } else {
            setIsHeaderFixed(false);
          }
        }
      } else {
        setIsHeaderFixed(false);
      }
      lastScrollPosition = window.scrollY;
    };
    let lastScrollPosition = 0;
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [programMenuOpen]);


  const fetchProgramsData = useCallback(
    throttle(async () => {
      if (cache) {
        setProgramDomains(cache.domains);
        setProgramMenuList(cache.menuList);
        return;
      }

      try {
        const res = await fetch(`/api/mega-menu`);
        const { data = {} } = await res.json();
        const domains = data?.programDomains || {};
        const menuList = data?.programMenuList || [];
        setProgramDomains(domains);
        setProgramMenuList(menuList);
        setCache({ domains, menuList });

        if (cacheTimeout.current) {
          clearTimeout(cacheTimeout.current);
        }
        cacheTimeout.current = setTimeout(() => {
          setCache(null); // Clear cache after timeout
        }, 5 * 60 * 1000); 
      } catch (error) {
        console.error("Error fetching program data:", error);
      }
    }, 2000),
    [cache]
  );

  const toggleMenu = useCallback(() => {
    toggleScroll(!menuOpen);
    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  function calculateTopForMenu(e) {
    toggleScroll(true);
    const headerHeight = headerRef?.current?.clientHeight;
    programMenuRef.current.style.height = `calc(100vh - calc(${headerHeight}px + ${
      isHeaderFixed ? 0 : 1.25
    }rem))`;
    programMenuRef.current.style.top = `calc(${headerHeight}px + ${
      isHeaderFixed ? 0 : 1.25
    }rem)`;
  }

  const GAEventsHandler = () => {
    pushToDataLayer({
      'event':'logo_click',
      'section_name':'top navigation bar',
      'page_type': getGlobalStateValue('pageType') 
    })
  }

  const fireWebEngage  = useDebounce(( value , results ) => {
    
    window.webengage.track('Search', {
      "Search Keyword" : value,
      "Dropdown Value" : results.map(({ Title })=> Title || "" ).join(', ')
    });

  }, 2000 ); 

  const onSearchHandler = (e) => {
    const results = searchPrograms(
      e.currentTarget.value,
      programMenuList?.MAIN_LIST,
    );

    if (results.length == 0)
      setResults([{ Title: "No programs found." }]);
    else setResults(results);
    setProgramMenuOpen(false);

    fireWebEngage( e.currentTarget.value, results);
  }

  const SearchBox = (
    <div className={cn("relative program-menu", styles.searchBoxContainer)}>
      <input
        type="text"
        autoFocus
        className="text-sm lg:text-xs"
        ref={ref}
        onKeyUp={onSearchHandler}
        // onBlur={() => setResults([])}
      />
      {
        <div
          onClick={() => {
            toggleSearch(false);
            setResults([]);
            if (ref.current) ref.current.value = "";
          }}
          className={cn("absolute", "headFootSprite", styles.searchClose)}
        ></div>
      }

      {!!results?.length && (
        <div className={cn(styles.resultsContainer)}>
          <div
            className={cn(
              styles.results,
              "bg-white font-raleway text-xs font-semibold  overflow-y-auto z-100",
            )}
          >
            {results.map((item: any, i: number) => (
              <div className={styles.resultsItem} key={i}>
                {item.url ? (
                  <a 
                  onClick={()=>{
                    pushToDataLayer({
                      event: "search_bar_fill",
                      cta_text: item?.Title || "NA",
                      section_name: "Top Navigation Bar",
                      page_type: getGlobalStateValue('pageType') ,
                    });
                  }}
                  title={item?.Title} href={`/${item?.url}`}>
                    {item?.Title}
                  </a>
                ) : (
                  item?.Title
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
  useEffect(()=>{
    // izooto configuration.
    (window as any)._izq = (window as any)._izq || []; 
    (window as any)._izq.push(["init"]);
  },[])



  return (
    <>
      {isDelayingEngatiScript && (
        <Script
          id="engati-script"
          async
          dangerouslySetInnerHTML={{
            __html: `!function(e,t,a){var c=e.head||e.getElementsByTagName("head")[0],n=e.createElement("script");n.defer=!0, n.type="text/javascript",n.src=t+"/static/js/widget.js?config="+JSON.stringify(a),c.appendChild(n)}(document,"https://app.engati.com",{bot_key:"bf113f277dd14593",welcome_msg:true,branding_key:"default",server:"https://app.engati.com",e:"p" });`,
          }}
          strategy="lazyOnload"
        />
      )}

      <Script async src="https://cdn.izooto.com/scripts/8895c7b531705a4b3f20e571e9c93b537113d747.js" strategy="afterInteractive" />
           
      <header
        ref={headerRef}
        className={cn("container", {
          [styles.fixedHeader]: isHeaderFixed,
          ["z-[1]"]: !isHeaderFixed,
        })}
      >
        <div
          className={cn(
            "mt-2 bg-primary-blue text-white mb-3 xl:mt-5 xl:flex xl:justify-between xl:items-center",
            styles.containerRadius
          )}
        >
          <div className="flex items-center justify-between ">
            {/* University logo */}
            {headerMenu?.Logo?.enabled && (
              <a
                className={cn("block", styles.logo)}
                onClick={GAEventsHandler}
                href="/"
                target={headerMenu.Logo?.target || "_self"}
              >
                <Image
                  src={"/_a/images/amity_logo_c.svg"}
                  alt={headerMenu.Logo?.alt || "Amity Logo"}
                  width={124}
                  height={44}
                  title="Amity Logo"
                  fetchPriority="high"
                  loading="eager"
                  priority={true}
                />
              </a>
            )}

            <div className="pt-6 pr-[28px] pb-[27px] flex lg:hidden laptop:pt-[26px] laptop:pr-[33px] laptop:pb-[25px]">
              {pathName !== "/predegree" && !isSponsoredCertificates && <a
                href="tel:18001023434"
                className={
                  "flex items-center border-[1.5px] border-[#1a325d] mr-2 px-2 py-2 text-[#1a325d] bg-[#ffc907] rounded-[50px]"
                }
                onClick={() => {
                  pushToDataLayer(GA_EVENTS.get(CALL_US_NAVBAR))
                  pushToDataLayer( {
                    ...GA_EVENTS.get(CALL_CHAT_INTERACTION),
                    cta_text: "call_us",
                    section_name: "header",
                    page_type: getGlobalStateValue('pageType') || "Home Page"
                  })
                  
                  window.webengage.track('Call Us Clicked', {
                    "Section Name" : "header"
                  });
                }}
    
              >
                <Image
                  src="/_a/icons/outgoing_call.svg"
                  alt="Call us"
                  width={12}
                  height={14}
                  className={cn(styles.callImg, "!h-[14px] !w-[12px]")}
                  title="Call us"
                />
                <span className={cn(styles.callTitle)}>Call</span>
              </a>
              }
              {pathName == "/predegree" && <a
            href="https://predegrees.amityonline.com/login/index.php"
            className={
              "flex items-center border-[1.5px] border-[#1a325d] mr-4 px-3 py-2 text-[#1a325d] bg-[#ffc907] rounded-[50px]"
            }
          >
            <span className="flex items-center">
              <svg style={{ width: '25px', height: '25px;', fill: "#1b325d"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 101">
                <path d="M50.4 54.5c10.1 0 18.2-8.2 18.2-18.2S60.5 18 50.4 18s-18.2 8.2-18.2 18.2 8.1 18.3 18.2 18.3zm0-31.7c7.4 0 13.4 6 13.4 13.4s-6 13.4-13.4 13.4S37 43.7 37 36.3s6-13.5 13.4-13.5zM18.8 83h63.4c1.3 0 2.4-1.1 2.4-2.4 0-12.6-10.3-22.9-22.9-22.9H39.3c-12.6 0-22.9 10.3-22.9 22.9 0 1.3 1.1 2.4 2.4 2.4zm20.5-20.5h22.4c9.2 0 16.7 6.8 17.9 15.7H21.4c1.2-8.9 8.7-15.7 17.9-15.7z"></path>
              </svg>
              <span className="text-[0.8rem] uppercase font-bold">Login</span>
            </span>
          </a>
              }

            {!isSponsoredCertificates &&  <VideoCall />}
              <div
                onClick={toggleMenu}
                className={cn(styles.searchImage, styles.searchIconNew, "headFootSprite", {
                  ["absolute top-1/2 -translate-y-1/2"]: showSearchBox,
                })}
              />
              <button onClick={toggleMenu} aria-label="Hamburger Menu">
                  <span className={menuOpen ? "block" : "hidden"}><CloseLogo /></span>
                  <span className={!menuOpen ? "block" : "hidden"}><HamburgerLogo /></span>
              </button>
            </div>
          </div>
          <DesktopMenu
            SearchBox={SearchBox}
            calculateTopForMenu={calculateTopForMenu}
            headerMenu={headerMenu}
            menuOpen={menuOpen}
            programDomains={memoizedDomains}
            programMenuList={memoizedMenuList}
            programMenuOpen={programMenuOpen}
            programMenuRef={programMenuRef}
            setProgramMenuOpen={setProgramMenuOpen}
            showSearchBox={showSearchBox}
            toggleScroll={toggleScroll}
            toggleSearch={toggleSearch}
            associatedPrograms={associatedPrograms}
            slug={pathName}
            getProgramsData={fetchProgramsData}
            isSponsoredCertificates={isSponsoredCertificates}
          />
        </div>

         <div
          className={cn(
            "container",
            styles.mobileContainer,
            menuOpen ? "block" : "hidden"
          )}
        >
 
          <MobileMenu
            SearchBox={SearchBox}
            headerMenu={headerMenu}
            menuOpen={menuOpen}
            programDomains={memoizedDomains}
            programMenuList={memoizedMenuList}
            programMenuOpen={programMenuOpen}
            results={results}
            setProgramMenuOpen={setProgramMenuOpen}
            associatedPrograms={associatedPrograms}
            getProgramsData={fetchProgramsData}
            isSponsoredCertificates={isSponsoredCertificates}
          />
        </div>
      </header>
    </>
  )
}
