import React, { useState } from "react";
import styles from "./VideoCall.module.scss";
import Camera from "./Camera";
import Close from "./Close";

export default function VideoCall({ isDesktop = false }) {
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);

  const onCloseHandler = () => {
    setIsHeaderHidden(!isHeaderHidden)
  }

  const onMouseOverhandler = () => {
    setIsHeaderHidden(false)
  }

  const onVideoHandler = () => {
    window.webengage.track("Video Call Us Clicked",{
      "Section Name": "header"
    })
  }

  return (
    <div className={styles.headerVideo}>
      <div className={isHeaderHidden? styles.videoTooltipClose : styles.videoTooltip}>
        <div className={isHeaderHidden ? styles.videoTooltipClose : styles.videoTooltipRightAlign}>
          <span className={styles.closeSpan} onClick={onCloseHandler}>
            <Close />
          </span>
        </div>
        Video call our career counsellor now!
      </div>
      <a href="https://widget01.popin.to/share/12338" aria-label="Video Call" target="__blank" {...(isDesktop && {onMouseOver : onMouseOverhandler})} onClick={onVideoHandler}  >
        <div className={styles.videoIcon}>
          <Camera />
        </div>
      </a>
    </div>
  );
}
